<template>
  <div class="container home">
    <div class="slider">
      <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel" data-interval="3000">
        <ol class="carousel-indicators">
          <li v-for="(row, key) in slider" v-bind:key="key" data-target="#carouselExampleFade" v-bind:data-slide-to="key" :class="{ active: key == 0 }"></li>
        </ol>
        <div class="carousel-inner">
          <div v-for="(row, key) in slider" v-bind:key="key" v-bind:class="{ 'carousel-item': true, active: key === 0 }">
            <img class="d-block w-100" :src="`${IMAGEURL}${row.image}`" />
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-12">
        <section>
          <header class="my-4">
            <h2>Offer Items</h2>
            <router-link :to="{ name: 'OfferItems' }" class="btn btn-primary bg-red">VIEW ALL</router-link>
          </header>
          <div class="grid">
            <a href="javascript:;" v-for="(item, key) in offerItems" v-bind:key="key" @click="itemDetails(item)">
              <div class="section-item d-flex rounded-lg justify-content-center flex-column">
                <div class="item-image-holder">
                  <a href="javascript:;" @click="itemDetails(item)">
                    <img :src="`${IMAGEURL}${item.thumb_image}`" class="img-responsive" />
                  </a>
                </div>
                <div class="pb-3 item-title">{{ item.name }}</div>
                <div class="item-price pb-2">
                  <span v-if="item.retail_status == 'Yes'">
                    ৳ {{ item.sales_price }} <strike>৳ {{ item.cost_price }}</strike></span
                  >
                  <span v-else
                    >৳ {{ item.wholesales_price }}
                    <span class="text-muted"
                      ><strike>৳ {{ item.wholesale_cost_price }}</strike></span
                    >
                  </span>
                </div>
              </div>
            </a>
          </div>
        </section>

        <section v-for="(row, key) in categoryItems" v-bind:key="key" class="mb-5">
          <header class="my-4">
            <h2>
              {{ row.category_name }}
            </h2>
            <router-link :to="{ name: 'Category', params: { id: row.id, name: row.slug } }" class="btn btn-primary bg-red">VIEW ALL</router-link>
          </header>
          <div class="grid">
            <a href="javascript:;" v-for="(item, key) in row.product_info" v-bind:key="key" @click="itemDetails(item)">
              <div class="section-item d-flex rounded-lg justify-content-center flex-column">
                <div class="item-image-holder">
                  <a href="javascript:;" @click="itemDetails(item)">
                    <img :src="`${IMAGEURL}${item.thumb_image}`" class="img-responsive" />
                  </a>
                </div>
                <div class="pb-3 item-title">{{ item.name }}</div>
                <div class="item-price pb-2">
                  <span v-if="item.retail_status == 'Yes'">৳ {{ item.sales_price }}</span>
                  <span v-else>৳ {{ item.wholesales_price }}</span>
                </div>
              </div>
            </a>
          </div>
        </section>

        <!-- <section class="service border-section small-section my-5">
          <div class="row">
            <div class="col-lg-4 service-block">
              <div class="shipping">
                <i class="fa fa-truck"></i>
                <h4>free shipping</h4>
                <p>free shipping world wide</p>
              </div>
            </div>
            <div class="col-lg-4 service-block">
              <div class="support">
                <i class="fa fa-phone"></i>
                <h4>24 X 7 service</h4>
                <p>online service for new customer</p>
              </div>
            </div>
            <div class="col-lg-4 service-block">
              <div class="offer">
                <i class="fa fa-bullhorn"></i>
                <h4>festival offer</h4>
                <p>new special festival offer</p>
              </div>
            </div>
          </div>
        </section> -->

        <!-- <section class="clients">
          <hooper :infiniteScroll="true" :itemsToShow="6" :centerMode="true">
            <slide v-for="(row, key) in clients" v-bind:key="key">
              <router-link :to="{ name: 'CMS', params: { slug: row.slug } }">
                <img class="img-fluid" :src="`${IMAGEURL}${row.image}`" />
                <h2 class="hooper-slider-title">{{ row.title }}</h2>
              </router-link>
            </slide>
          </hooper>
        </section> -->
      </div>
    </div>

    <!-- <div class="modal" id="imageModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body p-1">
            <img class="img-fluid" :src="`${IMAGEURL}${fullImageUrl}`" />
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import $ from "jquery";
// import CategoryMenu from "./CategoryMenu.vue";
// import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "HelloWorld",
  data() {
    return {
      storesLoading: true,
      popularItems: [],
      newItems: [],
      categoryItems: [],
      featuredVendor: [],
      clients: [],
      slider: [],
      stores: [],
      offerItems: [],
      fullImageUrl: null,
    };
  },
  components: {
    //Hooper,
    //Slide,
  },
  props: {
    msg: String,
  },
  methods: {
    showFullImage(fullImageUrl) {
      this.fullImageUrl = fullImageUrl;
      $("#imageModal").modal("show");
    },
    getStores() {
      this.$http
        .get("vendor")
        .then((response) => {
          //console.log(response);
          this.stores = response.data.data;
          this.storesLoading = false;
        })
        .catch((e) => {
          this.stores = [];
          this.storesLoading = true;
        });
    },
    getSlider() {
      this.$http
        .get("cms/Slider")
        .then((response) => {
          this.slider = response.data.data;
        })
        .catch((e) => {
          this.slider = [];
        });
    },
    getClients() {
      this.$http
        .get("cms/Gallery")
        .then((response) => {
          this.clients = response.data.data;
        })
        .catch((e) => {
          this.clients = [];
        });
    },
    // getPopularProducts() {
    //     this.$http.get('popular-product').then((response) => {
    //         this.popularItems = response.data.data;
    //         //console.log(this.popularItems);
    //     }).catch((e) => {
    //         this.popularItems = [];
    //     })
    // },
    // getLatestProducts() {
    //     this.$http.get('product').then((response) => {
    //         this.newItems = response.data.data;
    //     }).catch((e) => {
    //         this.newItems = [];
    //     })
    // },
    getFeaturedProducts() {
      this.$http
        .get("featured-product")
        .then((response) => {
          this.categoryItems = response.data.data;
          console.log(this.categoryItems);
        })
        .catch((e) => {
          //console.log(e);
          this.categoryItems = [];
        });
    },
    getOfferProducts() {
      this.$http
        .get("offer-products")
        .then((response) => {
          this.offerItems = response.data.data;
          console.log(response);
        })
        .catch((e) => {
          //console.log(e);
          this.categoryItems = [];
        });
    },
    // getFeaturedVendor() {
    //     this.$http.get('featured-vendor').then((response) => {
    //         this.featuredVendor = response.data.data;
    //     }).catch((e) => {
    //         //console.log(e);
    //         this.featuredVendor = [];
    //     })
    // },
    itemDetails(item) {
      this.$router.push("/item/" + item.id + "/" + item.slug);
    },
    storeDetails(item) {
      this.$router.push("/shop/" + item.slug);
    },
    addToCart(row) {
      let itemObj = {
        session_id: localStorage.getItem("session_id"),
        product_id: row.id,
        quantity: 1,
      };
      //console.log(itemObj);
      $(".cart-details-container").addClass("cart-in");
      //this.$store.commit("addItemToCart", itemObj);
      this.$store.dispatch("addToCart", itemObj);
    },
    addToWishlist(row) {
      if (!localStorage.getItem("token")) {
        $("#loginModal").modal("show");
      } else {
        let itemObj = {
          customer_id: localStorage.getItem("customer_id"),
          product_id: row.id,
        };
        this.$store.dispatch("addToWishlist", itemObj);
      }
    },
  },
  mounted() {
    //   this.getPopularProducts();
    //   this.getLatestProducts();
    this.getFeaturedProducts();
    this.getClients();
    this.getSlider();
    //  this.getFeaturedVendor();
    this.getStores();
    this.getOfferProducts();
    //this.$store.dispatch('addToCart')
    /*   this.flashMessage.success({message: 'Stock deleted successfully.'});
               this.flashMessage.error({message: 'Stock deleted successfully.'});
               this.flashMessage.warning({message: 'Stock deleted successfully.'});
               this.flashMessage.info({message: 'Stock deleted successfully.'});*/
  },
};
</script>
