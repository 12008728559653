import Vue from 'vue';
import Router from 'vue-router';

import Home from "./components/front/Home";
import ItemDetails from "./components/front/ItemDetails";
import Blog from "./components/front/Blog";
import BlogDetails from "./components/front/BlogDetails";
import Review from "./components/front/Review";
import Category from "./components/front/Category";
import OfferItems from "./components/front/OfferItems";
import Privacy from "./components/front/Privacy";
import About from "./components/front/About";
import Returns from "./components/front/Returns";
import Terms from "./components/front/Terms";
import CheckOut from "./components/front/CheckOut";
import OrderSuccess from "./components/front/OrderSuccess";
import OrderDetails from "./components/front/OrderDetails";
import Shop from "./components/front/Stores";
import ShopDetails from "./components/front/StoreDetails";

//customer panel
import Profile from "./components/customer/Profile";
import Orders from "./components/customer/Orders";
import Addresses from "./components/customer/Addresses";
import ChangePassword from "./components/customer/ChangePassword";
import Wishlist from "./components/customer/Wishlist";
import Login from "./components/front/Login";
import SignUp from "./components/front/Signup";
import ForgotPassword from "./components/front/ForgotPassword";
import CMS from "./components/front/CMS";
import OrderReview from "./components/front/OrderReview";

import OrderPaymentSuccess from "./components/front/OrderPaymentSuccess";
import OrderPaymentCancelled from "./components/front/OrderPaymentCancelled";
import OrderPaymentFailed from "./components/front/OrderPaymentFailed";
import News from "./components/front/News";
import Publications from "./components/front/Publications";
import Gallery from "./components/front/Gallery";
import GalleryDetails from "./components/front/GalleryDetails";
import Media from "./components/front/Media";
import Innovation from "./components/front/Innovation";

Vue.use(Router);

let router = new Router({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: `/`,
            name: 'Home',
            component: Home,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/innovation`,
            name: 'Innovation',
            component: Innovation,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/login`,
            name: 'Login',
            component: Login,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/sign-up`,
            name: 'SignUp',
            component: SignUp,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/forgot-password`,
            name: 'ForgotPassword',
            component: ForgotPassword,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/shops/`,
            name: 'Shop',
            component: Shop,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/shop/:slug`,
            name: 'ShopDetails',
            component: ShopDetails,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/item/:id/:slug`,
            name: 'ItemDetails',
            component: ItemDetails,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/item/:id`,
            name: 'ItemDetails',
            component: ItemDetails,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/category/:id/:name`,
            name: 'Category',
            component: Category,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/offer-items`,
            name: 'OfferItems',
            component: OfferItems,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/check-out`,
            name: 'CheckOut',
            component: CheckOut,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/order/:id`,
            name: 'OrderSuccess',
            component: OrderSuccess,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/privacy`,
            name: 'Privacy',
            component: Privacy,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/about`,
            name: 'About',
            component: About,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/returns`,
            name: 'Returns',
            component: Returns,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/terms`,
            name: 'Terms',
            component: Terms,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/photo-gallery`,
            name: 'Gallery',
            component: Gallery,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/multimedia-corner`,
            name: 'Media',
            component: Media,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/photo-gallery/:id`,
            name: 'GalleryDetails',
            component: GalleryDetails,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/payment/success`,
            name: 'OrderPaymentSuccess',
            component: OrderPaymentSuccess,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/payment/failed`,
            name: 'OrderPaymentFailed',
            component: OrderPaymentFailed,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/payment/cancelled`,
            name: 'OrderPaymentCancelled',
            component: OrderPaymentCancelled,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/page/:slug`,
            name: 'CMS',
            component: CMS,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/News-&-stories`,
            name: 'News',
            component: News,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/publications`,
            name: 'Publications',
            component: Publications,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/customer/profile`,
            name: 'Profile',
            component: Profile,
            meta: {
                layout: 'Customer',
                requiresAuth: true
            }
        },
        {
            path: `/customer/orders`,
            name: 'Orders',
            component: Orders,
            meta: {
                layout: 'Customer',
                requiresAuth: true
            }
        },
        {
            path: `/customer/order/:id`,
            name: 'OrderDetails',
            component: OrderDetails,
            meta: {
                layout: 'Customer',
                requiresAuth: true
            }
        },
        {
            path: `/order/review/:id/:order_id`,
            name: 'OrderReview',
            component: OrderReview,
            meta: {
                layout: 'Customer',
                requiresAuth: true
            }
        },
        {
            path: `/customer/wishlist`,
            name: 'Wishlist',
            component: Wishlist,
            meta: {
                layout: 'Customer',
                requiresAuth: true
            }
        },
        {
            path: `/customer/addresses`,
            name: 'Addresses',
            component: Addresses,
            meta: {
                layout: 'Customer',
                requiresAuth: true
            }
        },
        {
            path: `/customer/change-password`,
            name: 'ChangePassword',
            component: ChangePassword,
            meta: {
                layout: 'Customer',
                requiresAuth: true
            }
        },
        {
            path: `/blog`,
            name: 'Blog',
            component: Blog,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/blog/:id/:title`,
            name: 'BlogDetails',
            component: BlogDetails,
            meta: {
                layout: 'Front'
            }
        },
        {
            path: `/review`,
            name: 'Review',
            component: Review,
            meta: {
                layout: 'Front'
            }
        },
    ],
    mode: `history`,
    //  base: "dev",
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!localStorage.getItem('token')) {
            next({
                path: '/',
                params: { nextUrl: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router